<template>
    <div class="printContext">
        <div class="heading" style="border-bottom: 1px dashed;margin-bottom: 18px;">
            <h3 class="text-center">Dr.Mahmudul Hasan</h3>
            <p class="text-center" style="margin-top: -8px">(D.H.M.S) Govt Reg No: 32673</p>
            <p class="text-center" style="margin-top: -13px">Address: Mirpur-12, Dhaka -1216, Bangladesh.</p>
            <p class="text-center" style="margin-top: -16px">Call for Appointment <br> +8801714010479</p>
        </div>
        <div style="width: 100%;overflow: hidden;border-bottom: 1px dashed;margin-bottom: 10px;">
            <div style="width: 50%;float: left;">
                <span>Prescription Date: {{ prescription.prescriptions[0].prescription_written_date }}</span> <br>
                <span>Entry Date: {{ prescription.custom_today_date }} </span> <br>
                <span>Patient Name: {{ prescription.patient_name }} </span> <br>
                <span>Mobile Number: {{ prescription.mobile_number }}</span> <br>
                <span>Email: {{ prescription.e_mail }}</span>
            </div>
            <div style="width: 50%; float:right">
                <span>Gender: {{ prescription.gender }}</span> <br>
                <span>Age: {{ prescription.age }} </span>
            </div>
        </div>
        <div style="width: 100%; overflow: hidden;margin-top: 3px">
            <p style="text-align:center;background: green; color: white !important;padding: 5px">Prescription Details</p>
        </div>
        <div class="width: 100%; overflow: hidden" v-if="prescription.prescriptions.length > 0">
            <div v-for="(medicine, sl) in prescription.prescriptions" :key="sl" style="margin-left: 5px; margin-bottom:5px;">
                <p style="margin-bottom:0px;"> {{sl+1}} Medicine Name: {{ medicine.medicine_name }} </p>
                <span>Instruction: & Duration: {{ medicine.description }}</span>
            </div>
        </div>
        <div style="border-top: 1px dashed;margin-top: 18px;width:100%">
            <p style="text-align: right">
                Prescribed By
            </p>
            <h3 style="text-align: right;margin-top: -14px">Dr.Md. Mahmudul Hasan</h3>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        prescription: {
            prescriptions: []
        }
    }),
    mounted() {
        let body = document.body;
        body.classList.add("app-login");
        body.classList.add("p-0");
        this.printContent();
    },
    async created() {
        await this.$store.dispatch('prescription/getPrescriptions', this.$route.params.id);
        let prescriptionArray = await this.$store.getters['prescription/prescriptions'];
        
        if(prescriptionArray[0].prescriptions.length > 0){
            this.prescription = prescriptionArray[0];
        }
    },
    methods: {
        async printContent() {
            await new Promise(resolve => setTimeout(resolve, 1000));
            window.print();
            window.close();
        }
    }
}
</script>
<style scoped>
    *{
        color: black !important;
        font-weight: normal !important;
    }
    .printContext{
        padding: 5px;
        background: #fff;
        height: 100vh;
        /* width: 80%; */
        margin: 0 auto;
    }
    .patient_name {
        width: 40%;
        float: left;
    }
    .patient_age {
        width: 40%;
        float: left;
    }
    .patient_sex {
        width: 20%;
        float: left;
    }

</style>